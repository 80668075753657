/*-------------------------
    Button
-------------------------*/
.axil-btn {
	border-radius: 40px;
	font-size: 16px;
	font-weight: 500;
	letter-spacing: -0.04em;
	padding: 18px 30px;
	height: auto;
	text-align: center;
	transition: var(--transition);
	position: relative;
	z-index: 1;
	&.btn-borderd {
		border: var(--border-gray);
		color: var(--color-dark);
		background-color: transparent;
		&:hover {
			background-color: var(--color-primary);
			color: var(--color-white);
			border-color: var(--color-primary);
		}
	}
	&.btn-fill-primary {
		background-color: var(--color-primary);
		color: var(--color-white);
		border: 1px solid var(--color-primary);
		overflow: hidden;
		&::after {
			content: "";
			height: 300px;
			width: 300px;
			background-color: var(--color-link);
			border-radius: 50%;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translateY(-50%) translateX(-50%) scale(0);
			transition: 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
			z-index: -1;
		}
		&:hover {
			background-color: var(--color-link);
			border-color: var(--color-link);
			&::after {
				transform: translateY(-50%) translateX(-50%) scale(1);
			}
		}
	}
	&.btn-fill-white {
		background-color: var(--color-white);
		box-shadow: 0px 48px 48px 0px rgba(#000000, 0.12);
		&:hover {
			background-color: var(--color-accent1);
			color: var(--color-white);
		}
	}
	&.btn-large {
		padding: 23px 55px;
		@media #{$md-layout} {
			padding: 18px 35px;
		}
	}
	&.btn-large-fluid {
		padding: 23px;
		width: 100%;
	}
	&.btn-fluid {
		width: 100%;
	}
}
