/*-------------------------
    Service
-------------------------*/
.services-grid {
	display: flex;
	padding: 50px 50px 40px;
	border-radius: 30px;
	transition: var(--transition);
	margin-bottom: 40px;
	position: relative;
	z-index: 1;
	transition: var(--transition);
	@media #{$laptop-device} {
		padding: 50px 30px 40px;
	}
	@media #{$lg-layout} {
		padding: 40px 15px;
	}
	@media #{$md-layout} {
		padding: 40px 25px;
	}
	&:after {
		content: "";
		height: 100%;
		width: 100%;
		background: var(--gradient-dark);
		border: 1px solid;
		border-image-source: linear-gradient(
			0deg,
			#27272e 64.45%,
			rgba(74, 75, 86, 0.72) 100%
		);
		border-radius: 30px;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: -1;
		visibility: hidden;
		opacity: 0;
		transition: var(--transition);
	}
	.thumbnail {
		margin-right: 25px;
		max-width: 85px;
		@media #{$smlg-device} {
			max-width: 60px;
		}
		@media #{$md-layout} {
			width: 60px;
			margin-right: 15px;
		}
	}
	.content {
		flex: 1;
		.title {
			a {
				color: var(--color-white);
				&:hover {
					color: var(--color-primary);
				}
			}
		}
		p {
			color: var(--color-white);
			opacity: 0.5;
			letter-spacing: -0.025em;
			transition: var(--transition);
		}
		.more-btn {
			font-weight: 500;
			color: var(--color-white);
			position: relative;
			padding-left: 50px;
			&:before {
				content: "";
				width: 34px;
				height: 1px;
				background-color: var(--color-white);
				position: absolute;
				top: 50%;
				left: 0;
				transform: translateY(-50%);
				transition: var(--transition);
			}
		}
	}
	&:hover,
	&.active {
		&:after {
			visibility: visible;
			opacity: 1;
		}
		.thumbnail {
			&::after {
				transform: scale(1);
			}
		}
		.content {
			p {
				color: var(--color-light);
				opacity: 1;
			}
			.more-btn {
				color: var(--color-accent1);
				&:before {
					background-color: var(--color-accent1);
				}
			}
		}
	}
	&.service-style-2 {
		background: linear-gradient(
			180deg,
			#f8f8f8 0%,
			rgba(255, 255, 255, 0) 100%
		);
		border: 1px solid;
		border-image-source: linear-gradient(
			90deg,
			#ecf2f6 0%,
			rgba(236, 242, 246, 0) 70.31%
		);
		background-color: var(--color-white);
		margin-bottom: 30px;
		&:after {
			display: none;
		}
		.content {
			.title {
				a {
					color: var(--color-text-dark);
					&:hover {
						color: var(--color-primary);
					}
				}
			}
			p {
				color: var(--color-body);
				opacity: 1;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 3;
				-webkit-box-orient: vertical;
			}
			.more-btn {
				color: var(--color-body);
				&:before {
					background-color: var(--color-body);
				}
			}
		}
		&:hover,
		&.active {
			background-color: var(--color-light);
			.content {
				.more-btn {
					color: var(--color-primary);
					&:before {
						background-color: var(--color-primary);
					}
				}
			}
		}
	}
}

.service-scroll-navigation-area {
	position: relative;
	padding-top: 90px;
}
.service-scroll-nav {
	background-color: var(--color-white);
	z-index: 3;
	transition: var(--transition);
	padding-top: 5px;
	padding-bottom: 0;
	position: absolute;
	top: 0;
	width: 100%;
	ul {
		justify-content: center;
		flex: 1;
		border-bottom: 1px solid var(--color-mercury);
		li {
			margin: 0 10px;
			a {
				padding: 30px 10px;
				color: var(--color-dark);
				position: relative;
				@media #{$md-layout} {
					padding: 26px 0;
					font-size: 15px;
				}
				@media #{$sm-layout} {
					padding: 10px 0;
					font-size: 15px;
				}
				@media #{$large-mobile} {
					font-size: 14px;
				}
				&::after {
					content: "";
					height: 2px;
					width: 0;
					background-color: var(--color-primary);
					position: absolute;
					bottom: -1px;
					left: 0;
					transition: var(--transition);
				}
				&:hover {
					color: var(--color-primary);
					&::after {
						width: 100%;
					}
				}
			}
			&.current {
				a {
					color: var(--color-primary);
					&::after {
						width: 100%;
					}
				}
			}
		}
	}
}
/*******************************************************/
/****************** ## Service Area ********************/
/*******************************************************/
.service-item {
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
	margin-bottom: 30px;
	padding: 50px 50px 30px;
	background-color: #f8fafd;
}
@media only screen and (max-width: 767px) {
	.service-item {
		margin-top: 0;
	}
}
@media only screen and (max-width: 375px) {
	.service-item {
		padding-left: 25px;
		padding-right: 25px;
	}
}
.service-item .icon {
	line-height: 1;
	font-size: 30px;
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
	margin-bottom: 30px;
	color: var(--color-primary);
}
.service-item h5 {
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
	margin-bottom: 20px;
}
.service-item p {
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
}
.service-item:hover {
	background-color: var(--color-primary-hover);
}
.service-item:hover p,
.service-item:hover h5,
.service-item:hover h5 a,
.service-item:hover .icon {
	color: var(--color-text-dark);
}
.service-item.style-two {
	padding-top: 40px;
	text-align: center;
	padding-bottom: 35px;
}
@media only screen and (min-width: 376px) {
	.service-item.style-two {
		padding-left: 40px;
		padding-right: 40px;
	}
}
.service-item.style-two .icon {
	font-size: 35px;
	margin-bottom: 25px;
}
@media only screen and (min-width: 376px) {
	.service-item.style-two h5 {
		font-size: 22px;
	}
}
.service-item.style-two .read-more {
	padding-right: 0;
}
.service-item.style-two .read-more i {
	left: 50%;
	-webkit-transform: rotate(-45deg) translate(-50%);
	-ms-transform: rotate(-45deg) translate(-50%);
	transform: rotate(-45deg) translate(-50%);
}
.service-item.style-two .read-more:hover i {
	left: calc(100% - 20px);
	-webkit-transform: rotate(-45deg) translate(0);
	-ms-transform: rotate(-45deg) translate(0);
	transform: rotate(-45deg) translate(0);
}
.service-item.style-two .read-more:hover span {
	-webkit-transform: translate(-20px);
	-ms-transform: translate(-20px);
	transform: translate(-20px);
}
// .service-item.style-two:hover {
// 	background-image: url(../images/services/service-bg.png);
// }
.service-item.style-two:hover .read-more {
	color: var(--color-text-dark);
}
.service-item.style-three {
	background: #f8fafd;
	border: 2px solid #f8fafd;
}
@media only screen and (min-width: 376px) {
	.service-item.style-three {
		padding-left: 40px;
		padding-right: 40px;
	}
}
.service-item.style-three .icon {
	font-size: 60px;
}
.service-item.style-three .number {
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
	margin-top: -10px;
	margin-bottom: 20px;
}
.service-item.style-three h4 {
	margin-bottom: 18px;
}
@media only screen and (max-width: 479px) {
	.service-item.style-three h4 {
		font-size: 20px;
	}
}
.service-item.style-three h4 a:hover {
	text-decoration: underline;
}
.service-item.style-three:hover {
	background: #0a1019;
	border-color: var(--color-primary-hover);
}
.service-item.style-three:hover .more,
.service-item.style-three:hover .icon {
	color: var(--color-primary-hover);
}
.service-item.style-three:hover h4 a,
.service-item.style-three:hover .number {
	color: white;
}
.service-item.style-three:hover p {
	color: rgba(255, 255, 255, 0.65);
}

/* Service Style Three */
.service-three-item {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
	margin-bottom: 30px;
	background: #f8fafd;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	padding: 35px 35px 30px;
	height: calc(100% - 30px);
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}
@media only screen and (max-width: 375px) {
	.service-three-item {
		padding-left: 25px;
		padding-right: 25px;
	}
}
.service-three-item .title-icon {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 40px;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}
.service-three-item .title-icon h5 {
	max-width: 60%;
}
.service-three-item .title-icon h3 {
	font-size: 30px;
}
.service-three-item .content p {
	color: var(--color-primary-hover);
}
.service-three-item .read-more.style-two {
	color: var(--color-primary-hover);
}
.service-three-item:hover {
	background: var(--color-primary);
	-webkit-box-shadow: 0px 4px 60px rgba(109, 131, 204, 0.2);
	box-shadow: 0px 4px 60px rgba(109, 131, 204, 0.2);
}

.service-three-area:after {
	z-index: -1;
	width: 900px;
	height: 900px;
	opacity: 0.2;
	border-radius: 50%;
	-webkit-filter: blur(200px);
	filter: blur(200px);
	position: absolute;
	left: -300px;
	bottom: -400px;
	background: var(--ygency-primary-color);
	-webkit-animation: zoomInOut 5s infinite;
	animation: zoomInOut 5s infinite;
}

/* Service Style Four */
.service-four-item {
	z-index: 1;
	padding: 10px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
	position: relative;
	background: #f8fafd;
	margin-bottom: 30px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	height: calc(100% - 30px);
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}
.service-four-item .content {
	height: 100%;
	padding: 30px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}
.service-four-item .content h5 {
	margin-bottom: 0;
	margin-top: 18px;
}
.service-four-item .icon-btn {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}
.service-four-item .icon-btn > i {
	line-height: 1;
	font-size: 60px;
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
	color: var(--ygency-primary-color);
}
.service-four-item .icon-btn .more-btn {
	width: 50px;
	height: 50px;
	line-height: 50px;
	text-align: center;
	background: white;
	color: var(--ygency-primary-color);
	-webkit-transform: translate(30px, -30px);
	-ms-transform: translate(30px, -30px);
	transform: translate(30px, -30px);
}
.service-four-item .icon-btn .more-btn > i {
	-webkit-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	transform: rotate(-45deg);
}
.service-four-item .image img {
	width: 100%;
}
.service-four-item:after,
.service-four-item:before {
	content: "";
	z-index: -1;
	width: 110px;
	height: 110px;
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
	position: absolute;
	right: 30px;
	top: 35px;
	border-radius: 50%;
}
.service-four-item:before {
	background: -webkit-gradient(
		linear,
		left top,
		left bottom,
		from(rgba(0, 0, 0, 0.08)),
		to(rgba(255, 255, 255, 0))
	);
	background: -webkit-linear-gradient(
		top,
		rgba(0, 0, 0, 0.08) 0%,
		rgba(255, 255, 255, 0) 100%
	);
	background: -o-linear-gradient(
		top,
		rgba(0, 0, 0, 0.08) 0%,
		rgba(255, 255, 255, 0) 100%
	);
	background: linear-gradient(
		180deg,
		rgba(0, 0, 0, 0.08) 0%,
		rgba(255, 255, 255, 0) 100%
	);
}
.service-four-item:after {
	opacity: 0;
	background: -webkit-gradient(
		linear,
		left top,
		left bottom,
		from(rgba(9, 9, 9, 0.2)),
		to(rgba(255, 255, 255, 0))
	);
	background: -webkit-linear-gradient(
		top,
		rgba(9, 9, 9, 0.2) 0%,
		rgba(255, 255, 255, 0) 100%
	);
	background: -o-linear-gradient(
		top,
		rgba(9, 9, 9, 0.2) 0%,
		rgba(255, 255, 255, 0) 100%
	);
	background: linear-gradient(
		180deg,
		rgba(9, 9, 9, 0.2) 0%,
		rgba(255, 255, 255, 0) 100%
	);
}
.service-four-item:hover {
	background: var(--ygency-primary-color);
}
.service-four-item:hover .content h5 a,
.service-four-item:hover .content .icon-btn > i {
	color: var(--ygency-dark-color);
}
.service-four-item:hover .more-btn {
	background: black;
}
.service-four-item:hover:before {
	opacity: 0;
}
.service-four-item:hover:after {
	opacity: 1;
}

/* Services Home Six */
.services-five-wrapper {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-right: -70px;
}
@media only screen and (max-width: 1199px) {
	.services-five-wrapper {
		margin-right: -25px;
	}
}
.services-five-wrapper .service-five-item {
	margin-right: 70px;
}
@media only screen and (max-width: 1199px) {
	.services-five-wrapper .service-five-item {
		margin-right: 25px;
	}
}

.service-five-item {
	opacity: 0.2;
	color: black;
	font-size: 48px;
	font-weight: 600;
	position: relative;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin-bottom: 50px;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	letter-spacing: -0.96px;
}
@media only screen and (max-width: 1399px) {
	.service-five-item {
		font-size: 40px;
	}
}
@media only screen and (max-width: 991px) {
	.service-five-item {
		font-size: 30px;
		margin-bottom: 30px;
	}
}
@media only screen and (max-width: 575px) {
	.service-five-item {
		font-size: 25px;
	}
}
.service-five-item span {
	z-index: 1;
	position: relative;
}
.service-five-item i {
	float: right;
	font-size: 0.8em;
	margin-left: 50px;
}
@media only screen and (max-width: 1199px) {
	.service-five-item i {
		margin-left: 15px;
	}
}
.service-five-item .image {
	z-index: -1;
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
	max-width: 300px;
	position: absolute;
	right: -222px;
	top: -75%;
	-webkit-transform: scale(0) rotate(-30deg);
	-ms-transform: scale(0) rotate(-30deg);
	transform: scale(0) rotate(-30deg);
}
@media only screen and (max-width: 991px) {
	.service-five-item .image {
		right: -50px;
		max-width: 200px;
	}
}
.service-five-item:hover {
	opacity: 1;
	color: black;
	text-decoration: underline;
}
.service-five-item:hover .image {
	z-index: 2;
	-webkit-transform: scale(1) rotate(6.2deg);
	-ms-transform: scale(1) rotate(6.2deg);
	transform: scale(1) rotate(6.2deg);
}

.services-area-six:after,
.services-area-six:before {
	content: "";
	z-index: -1;
	width: 850px;
	height: 850px;
	opacity: 0.15;
	border-radius: 50%;
	-webkit-filter: blur(300px);
	filter: blur(300px);
	position: absolute;
	right: -250px;
	top: -130px;
	background: var(--ygency-primary-color);
	-webkit-animation: zoomInOut 5s infinite;
	animation: zoomInOut 5s infinite;
}

.services-area-six:before {
	left: -250px;
}

.service-six-item {
	background: white;
	margin-bottom: 30px;
	padding: 30px 30px 30px 40px;
	-webkit-box-shadow: 0px 4px 60px 30px rgba(0, 0, 0, 0.05);
	box-shadow: 0px 4px 60px 30px rgba(0, 0, 0, 0.05);
}
@media only screen and (min-width: 480px) {
	.service-six-item {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
	}
}
.service-six-item .icon {
	z-index: 1;
	width: 50px;
	height: 50px;
	font-size: 40px;
	margin-right: 38px;
	position: relative;
	color: var(--ygency-dark-color);
}
.service-six-item .icon:after {
	content: "";
	width: 100%;
	height: 100%;
	z-index: -1;
	border-radius: 50%;
	position: absolute;
	right: -8px;
	bottom: -12px;
	background: var(--ygency-primary-color);
}
.service-six-item .content {
	margin-right: 10px;
}
@media only screen and (max-width: 479px) {
	.service-six-item .content {
		margin: 30px 0 20px;
	}
}
.service-six-item .content h5 {
	margin-bottom: 8px;
}
.service-six-item .content p {
	opacity: 0.75;
	margin-bottom: 0;
	line-height: 1.5;
}
.service-six-item .details-btn {
	-webkit-box-flex: 0;
	-ms-flex: none;
	flex: none;
	width: 50px;
	height: 50px;
	font-size: 16px;
	margin-left: auto;
	line-height: 50px;
	-ms-flex-item-align: center;
	-ms-grid-row-align: center;
	align-self: center;
}
.service-six-item:hover .content h5 a {
	text-decoration: underline;
}
.service-six-item:hover .details-btn {
	color: #0a1019;
	background: var(--ygency-primary-color);
	border-color: var(--ygency-primary-color);
}

/* Why Choose Us */
.why-choose-right {
	position: relative;
	max-width: -webkit-max-content;
	max-width: -moz-max-content;
	max-width: max-content;
}
@media only screen and (min-width: 1200px) {
	.why-choose-right {
		margin-left: auto;
	}
}
.why-choose-right .why-choose-border-shape {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	border: 100px solid var(--ygency-primary-color);
	border-radius: 50%;
	z-index: -1;
}
@media only screen and (max-width: 479px) {
	.why-choose-right .why-choose-border-shape {
		border-width: 60px;
	}
}
.why-choose-right .text-shape {
	position: absolute;
	right: 2.5%;
	top: 8.5%;
	width: 44%;
}
@media only screen and (max-width: 1599px) {
	.why-choose-right.style-two {
		margin-left: 0;
	}
}
.why-choose-right.style-two .why-choose-border-shape {
	padding-bottom: 66%;
	height: auto;
	width: 110%;
	right: -34%;
	top: 50%;
	left: auto;
	z-index: 1;
	-webkit-transform: translateY(-50%) rotate(46deg);
	-ms-transform: translateY(-50%) rotate(46deg);
	transform: translateY(-50%) rotate(46deg);
	border-color: var(--ygency-primary-color) var(--ygency-primary-color)
		transparent transparent;
}
@media only screen and (max-width: 767px) {
	.why-choose-right.style-two .why-choose-border-shape {
		right: -5%;
	}
}
@media only screen and (max-width: 479px) {
	.why-choose-right.style-two .why-choose-border-shape {
		right: 0;
		padding-bottom: 82%;
	}
}
@media only screen and (max-width: 375px) {
	.why-choose-right.style-two .why-choose-border-shape {
		padding-bottom: 72%;
	}
}
.why-choose-right.style-two .text-shape {
	top: 50%;
	width: 48%;
	right: -30%;
	z-index: 2;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}
@media only screen and (max-width: 767px) {
	.why-choose-right.style-two .text-shape {
		right: 0;
	}
}
.why-choose-right.style-three {
	max-width: 540px;
	padding-right: 15%;
}
.why-choose-right.style-three .image-one {
	margin-right: 22%;
}
.why-choose-right.style-three .image-two {
	margin-left: 22%;
}
.why-choose-right.style-three .why-choose-border-shape {
	top: 55%;
	width: 100%;
}
@media only screen and (min-width: 576px) {
	.why-choose-right.style-three .why-choose-border-shape {
		right: -3%;
	}
}
@media only screen and (max-width: 479px) {
	.why-choose-right.style-three .why-choose-border-shape {
		padding-bottom: 75%;
	}
}
@media only screen and (max-width: 375px) {
	.why-choose-right.style-three .why-choose-border-shape {
		padding-bottom: 64%;
	}
}
.why-choose-right.style-three .text-shape {
	top: 55%;
}
@media only screen and (min-width: 576px) {
	.why-choose-right.style-three .text-shape {
		right: -6%;
	}
}

@media only screen and (min-width: 376px) {
	.why-choose-left-content h5 {
		font-size: 22px;
	}
}

.why-choose-bg {
	z-index: -1;
	position: absolute;
	right: 0;
	bottom: 15%;
	max-width: 70%;
}

/* Service Details */
.service-about-image {
	padding-bottom: 15%;
	padding-right: 15%;
}

.service-about-box {
	max-width: 70%;
	padding: 50px 60px;
	position: absolute;
	right: 0;
	bottom: 0;
}
@media only screen and (max-width: 1199px) {
	.service-about-box {
		padding-left: 50px;
		padding-right: 50px;
	}
}
@media only screen and (max-width: 479px) {
	.service-about-box {
		padding: 30px 25px;
	}
}
@media only screen and (max-width: 375px) {
	.service-about-box {
		max-width: 85%;
	}
}
.service-about-box .read-more {
	font-size: 55px;
	margin-left: -10px;
	color: var(--ygency-dark-color);
	margin-bottom: 30px;
}
@media only screen and (max-width: 479px) {
	.service-about-box .read-more {
		font-size: 35px;
	}
}
.service-about-box .read-more i {
	font-weight: 300;
}
.service-about-box h3 {
	margin-bottom: 0;
}
@media only screen and (min-width: 1200px) {
	.service-about-box h3 {
		font-size: 35px;
	}
}
@media only screen and (max-width: 479px) {
	.service-about-box h3 {
		font-size: 25px;
	}
}
.service-about-box h3 a {
	color: var(--ygency-dark-color);
}

.service-page-two-service:before,
.service-page-two-service:after {
	content: "";
	z-index: -1;
	width: 100px;
	height: 100px;
}

.service-page-two-service:before {
	position: absolute;
	left: 14%;
	top: 50%;
	-webkit-animation: rotated_circle linear 10s infinite;
	animation: rotated_circle linear 10s infinite;
	// background: url(../images/projects/ellipse-left.png) no-repeat;
}

.service-page-two-service:after {
	position: absolute;
	right: 10%;
	bottom: 25%;
	-webkit-animation: rotated_circle_reverse linear 10s infinite;
	animation: rotated_circle_reverse linear 10s infinite;
	// background: url(../images/projects/ellipse-right.png) no-repeat right;
}

.read-more {
	font-weight: 500;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	color: var(--ygency-secondary-color);
	text-decoration: underline;
	text-transform: capitalize;
	text-underline-position: under;
}
.read-more i {
	float: right;
	font-weight: 400;
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
	margin-left: 8px;
	-webkit-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	transform: rotate(-45deg);
}
.read-more:hover {
	color: var(--ygency-primary-color);
	text-decoration: underline;
}
.read-more:hover i {
	margin-left: 13px;
}
.read-more.style-two {
	color: var(--ygency-body-color);
	position: relative;
	padding-right: 28px;
}
.read-more.style-two span {
	opacity: 0;
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	transition: 0.5s;
	-webkit-transform: translate(20px);
	-ms-transform: translate(20px);
	transform: translate(20px);
}
.read-more.style-two i {
	left: 0;
	margin-left: 0;
	font-size: 22px;
	position: absolute;
}
.read-more.style-two:hover span {
	opacity: 1;
	-webkit-transform: translate(0);
	-ms-transform: translate(0);
	transform: translate(0);
}
.read-more.style-two:hover i {
	font-size: 16px;
	margin-left: 13px;
	left: calc(100% - 28px);
}
.read-more.color-primary {
	color: var(--ygency-primary-color);
}

// .fal,
// .far {
// 	font-family: "Font Awesome 5 Pro";
// }

// .far {
// 	font-weight: 400;
// }

// .fa-arrow-right:before {
// 	content: "\f061";
// }

// .fa-arrow-right {
// 	content: "\f061";
// }

// .fal,
// .far {
// 	font-family: "Font Awesome 5 Pro";
// }
